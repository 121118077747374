<template>
    <div>
       
            <v-card flat>
                <v-container>
                    <v-toolbar flat>
                        <v-toolbar-title>الإعدادات</v-toolbar-title>
                    </v-toolbar>

                    <v-divider></v-divider>

                    <v-tabs vertical class="mt-10" active-class="secondary--text grey lighten-4 rounded-lg">
                        <v-tab class="mr-2"> <h5>الملف الشخصي</h5> </v-tab>
                        <v-tab class="mr-2"> <h5>كلمة السر</h5> </v-tab>
                        <v-tab class="mr-2"> <h5>المظر الخارجي</h5> </v-tab>

                        <!-- tab-item-1 -->
                        <v-tab-item>
                            <v-card flat class="pr-16">
                                <v-card flat class="profile-caver" :loading="$global.state.loading" height="150"></v-card>
                                <div class="d-flex justify-start">
                                    <div class="text-center mt-n10 mr-4">
                                        <div class="d-flex justify-center align-center shadow profile-img">
                                            <small v-if="body.hotelLogoUrl == '' || body.hotelLogoUrl == null ">صورة الشخصية</small>
                                            <v-img v-else :src="`${url}${body.hotelLogoUrl}`"></v-img>
                                        </div>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs" v-on="on" small icon color="primary" class="mt-n16" @click="dialogLogoUrl = true">
                                                    <v-icon>mdi-camera</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>تغير الشعار الخاص بالفندق</span>
                                        </v-tooltip>
                                    </div>
                                    <div class="mt-4 mr-4">
                                        <h4>الملف الشخصي</h4> 
                                        <span>تحديث صورتك وبياناتك الشخصية.</span>
                                    </div>
                                    <v-spacer/>
                                    <div class="pa-4">
                                        <v-btn color="grey darken-3" dark :loading="$global.state.loading" class="btn" @click="editProfile()">حفظ</v-btn>
                                    </div>
                                </div>

                                <v-card flat class="card">
                                    <v-form lazy-validation v-model="valid">
                                        <v-container>
                                            <v-card flat>
                                                <v-row no-gutters>
                                                    <!-- fullName -->
                                                    <v-col cols="12" md="2"> <h5> الاسم</h5> </v-col>
                                                    <v-col cols="12" md="6">
                                                        <v-text-field
                                                            v-model="item.fullName"
                                                            placeholder="مركز الكفيل لتقنية المعلومات"
                                                            color="info"
                                                            :rules="[$global.state.required()]"
                                                            required
                                                            dense
                                                            outlined
                                                            hide-details="auto"
                                                        />
                                                    </v-col>

                                                    <v-col cols="12" md="12"> <v-divider class="my-6"></v-divider> </v-col>
                                                    
                                                    <!-- userName -->
                                                    <v-col cols="12" md="2"> <h5> إسم المستخدم</h5> </v-col>
                                                    <v-col cols="12" md="6">
                                                        <v-text-field
                                                            v-model="item.userName"
                                                            hint="يجب إدخال إسم المستخدم بالإنكليزي"
                                                            placeholder="kf"
                                                            color="info"
                                                            :rules="[$global.state.required()]"
                                                            required
                                                            dense
                                                            outlined
                                                            hide-details="auto"
                                                        />
                                                    </v-col>

                                                    <v-col cols="12" md="12"> <v-divider class="my-6"></v-divider> </v-col>

                                                    <!-- provinceId  -->
                                                    <v-col cols="12" md="2"> <h5>المحافظ</h5> </v-col>
                                                    <v-col cols="12" md="6">
                                                        <v-autocomplete
                                                            v-model="item.provinceId"
                                                            item-text="name"
                                                            item-value="id"
                                                            :items="$global.state.provinces"
                                                            color="info"
                                                            placeholder="المحافظة"
                                                            no-data-text="لا توجد بيانات"
                                                            hide-details
                                                            prepend-inner-icon="place"
                                                            outlined
                                                            dense
                                                        />
                                                    </v-col>

                                                    <v-col cols="12" md="12"> <v-divider class="my-6"></v-divider> </v-col>

                                                    <!-- addres -->
                                                    <v-col cols="12" md="2"> <h5>العنوان </h5> </v-col>
                                                    <v-col cols="12" md="6">
                                                        <v-text-field
                                                            v-model="item.addres"
                                                            placeholder="النجف"
                                                            color="info"
                                                            required
                                                            dense
                                                            outlined
                                                            hide-details="auto"
                                                        />
                                                    </v-col>

                                                </v-row>
                                            </v-card>
                                        </v-container>
                                    </v-form>
                                </v-card>
                            </v-card>
                        </v-tab-item>

                        <!-- tab-item-2 -->
                        <v-tab-item>
                            <v-card flat class="pr-16">
                                    <ChangePassword />
                            </v-card>
                        </v-tab-item>

                        <!-- tab-item-3 -->
                        <v-tab-item>
                            <v-card flat class="pr-16">
                                <v-row>
                                    <v-col cols="12" md="3">
                                        <v-card flat class="d-flex align-center pa-3" :class="!$vuetify.theme.isDark ? 'grey lighten-3' : ''">
                                            <div>{{ $vuetify.theme.isDark ? "الوضع الفاتح" : "الوضع الداكن" }}</div>
                                            <v-spacer></v-spacer>
                                            <div class="mt-n4">
                                                <themChanger />
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-tab-item>

                      
                    </v-tabs>
                </v-container>
            </v-card>

        <!-- dialog Hotel Logo Url -->
        <!-- <v-dialog v-model="dialogHotelLogoUrl" width="40$" persistent>
            <v-card flat class="rounded-lg">
                <div class="px-4 py-2">تغير الشعار الخاص بالفندق</div>
                <v-divider></v-divider>
                <div class="pa-4">
                    <v-form>
                        <v-file-input
                            @change="handlehotelLogoUrlUpload()"
                            v-model="hotelLogoUrl"
                            chips
                            accept="image/png, image/jpeg, image/bmp, image/jpg, .pdf"
                            color="error"
                            :loading="hotelLogoUrlloading"
                            prepend-icon
                            hide-details
                            class="mt-4"
                            append-icon="mdi-camera"
                            label="حدد الشعار"
                            outlined
                            filled
                        />
                    </v-form>
                </div>

                <v-card-actions>
                    <v-btn
                        @click="updateHotelLogoUrl"
                        :disabled="!hotelLogoUrlToDb"
                        text
                        color="primary"
                    >حـفظ</v-btn>
                    <v-btn @click="close" text color="error" class="mr-2">إلغاء</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    components: {
        ThemChanger: () => import("@/layouts/ThemeChanger"),
        ChangePassword: () => import("./changePassword.vue"),
    },

    data() {
        return {
            item: {},
            valid: true,
            body: {},
           
        };
    },
    created() {
        this.getUserInfo();
    },

    computed: {
        ...mapState("auth", ["userData"]),
    },

    methods: {
        getUserInfo() {
            this.item = this.userData.userData;
            this.item.userName = this.userData.userData.phoneNumber;
            this.item.provinceId = this.userData.userData.province ? this.userData.userData.province.id : "";         
        },

        editProfile(){
            this.$global.state.loading = true;
            this.$http
                .put(`${this.$route.meta.endPoint}/updateUserProfile`, this.item)
                .then((res) => {
                    this.$store.dispatch("toastification/setToast", { message: `${res.data.message}`, type: "success" });
                    this.userData.userData.fullName = this.item.fullName;
                    this.userData.userData.userName = this.item.userName;
                    localStorage.setItem("userData", JSON.stringify(this.userData));
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", { message: `${err.data.message}`, type: "error"});
                })
                .finally(() => (this.$global.state.loading = false));
        },

        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },
    },
};
</script>



<style lang="scss" scoped>

.profile-caver {
    border-radius: 10px 45px 10px 10px !important;
    -moz-border-radius: 10px 45px 10px 10px !important;
    -webkit-border-radius: 10px 35px 10px 10px !important;

    background: rgba(242, 246, 248, 1);
    background: -moz-linear-gradient(
        left,
        rgba(242, 246, 248, 1) 0%,
        rgba(216, 225, 231, 1) 41%,
        rgba(224, 239, 249, 1) 100%
    );
    background: -webkit-gradient(
        left top,
        right top,
        color-stop(0%, rgba(242, 246, 248, 1)),
        color-stop(41%, rgba(216, 225, 231, 1)),
        color-stop(100%, rgba(224, 239, 249, 1))
    );
    background: -webkit-linear-gradient(
        left,
        rgba(242, 246, 248, 1) 0%,
        rgba(216, 225, 231, 1) 41%,
        rgba(224, 239, 249, 1) 100%
    );
    background: -o-linear-gradient(
        left,
        rgba(242, 246, 248, 1) 0%,
        rgba(216, 225, 231, 1) 41%,
        rgba(224, 239, 249, 1) 100%
    );
    background: -ms-linear-gradient(
        left,
        rgba(242, 246, 248, 1) 0%,
        rgba(216, 225, 231, 1) 41%,
        rgba(224, 239, 249, 1) 100%
    );
    background: linear-gradient(
        to right,
        rgba(242, 246, 248, 1) 0%,
        rgba(216, 225, 231, 1) 41%,
        rgba(224, 239, 249, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f6f8', endColorstr='#e0eff9', GradientType=1 );
}

.profile-img {
    position: relative;
    background-color: #F9F5EB;
    width: 135px;
    height: 135px;
    border-radius: 50%;
    border: 5px solid #fff !important;   
}
</style>














